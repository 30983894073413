import React from "react";
import { PageHeader, Tag } from 'antd';
import ContinuaSiguienteTarea from "../components/alertas/ContinuaSiguienteTarea";
import HoraTareaEnCursoOlvidada from "../components/alertas/HoraTareaEnCursoOlvidada";
import TareaEnCurso from "../components/alertas/TareaEnCurso";
import { useSelector } from "react-redux";
import {isOldOpenTask} from "../helpers/utils";


const Dashboard = () => {

    const userApp = useSelector(state => state.userApp.userApp);
    const taskOnPlay = useSelector(state => state.taskOnPlay.taskOnPlay);
    const oldTaskOpen = taskOnPlay? isOldOpenTask(taskOnPlay) : false;

    return (
        <div>
            <PageHeader
                title={`${userApp.first_name} ${userApp.last_name}`}
                tags={[
                    (userApp.administrador && <Tag key="administrador" color="blue">Administrador</Tag>)
                ]}
                subTitle={userApp.email}
            >
                { oldTaskOpen && taskOnPlay && /* Tarea vieja abierta */
                    <HoraTareaEnCursoOlvidada />
                }

                { !oldTaskOpen && taskOnPlay && /* Tarea en curso */
                    <TareaEnCurso />
                }

                { !oldTaskOpen && !taskOnPlay &&
                    <ContinuaSiguienteTarea />
                }


            </PageHeader>
        </div>
    );
}

export default Dashboard;