import actionTypes from '../actions/ultimaActualizacion'
import moment from "moment";

const initialState = {
    ultimaActualizacion: moment().format('YYYYMMDDHHmm')
}

const ultimaActualizacion = (state = initialState, { type, payload }) => {
    switch(type) {
        case actionTypes.UPDATE_LAST_UPDATE:
            return {
                ultimaActualizacion: payload
            };
        default:
            return {
                ...state
            }
    }
}

export default ultimaActualizacion
