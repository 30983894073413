import React, { useState } from "react";
import  { GoogleLogin } from "react-google-login";
import { LoginOutlined } from '@ant-design/icons';

import { Button } from 'antd';
import { baseUrl } from "../../helpers/fetchHelper";
import { useDispatch } from "react-redux";
import { setUserApp } from "../../redux/actions/userApp";

const clientId = '607429418627-um70ol73tdpc40ka66mtmrte7olnp3oi.apps.googleusercontent.com';

const GoogleLoginButton = ({ setUserGoogle, setErrorCookies = () => null, errorConectandoServidor = false }) => {

    const dispatch = useDispatch();

    const [ error, setError ] = useState(false);

    const onSuccess = async (res) => {
        setError(false);

        const googleUser = res.profileObj;
        const id_token = res.getAuthResponse().id_token;
        const data = { id_token };

        const dataResponse = await fetch( `${baseUrl}/api/auth/google`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify( data )
        });

        const { error, result } = await dataResponse.json();
        if(!error){
            const { usuario = null, token = null } = result;

            if(usuario){
                usuario.token = token;
                setUserGoogle(googleUser);

                dispatch(setUserApp(usuario));
            }
        }

    }

    const onFailure = (res) => {
        setError(true);
        if(res.error && res.details === "Cookies are not enabled in current environment."){
            setErrorCookies(true);
        }
    }

    return (
        <GoogleLogin
            clientId={clientId}
            onSuccess={onSuccess}
            onFailure={onFailure}
            cookiePolicy={'single_host_origin'}
            style={{ marginTop: '1rem'}}
            isSignedIn={true}
            render={renderProps => (
                <Button type="primary" onClick={renderProps.onClick} disabled={renderProps.disabled || error}>
                    Iniciar sesión con Google<LoginOutlined />
                </Button>
            )}
            disabled={errorConectandoServidor}
        />
    );
}

export default GoogleLoginButton;