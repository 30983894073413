import React from "react";
import {Button, List, Descriptions, Skeleton} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ListaUltimasTareasProyecto from "../ListaUltimasTareasProyecto";
import ListaHitosProyecto from "../ListaHitosProyecto";
import {useFetchTasks} from "../../hooks/useFetchTasks";
import moment from "moment";
import {useFetchHitos} from "../../hooks/useFetchHitos";

const FilaProyecto = ({ proyecto }) => {

    const { data: tasks, loading: loadingTasks } = useFetchTasks({
        proyecto: proyecto.id,
        desde: moment().subtract(30, "days").toDate()
    });

    const { data: hitos, loading: loadingHitos } = useFetchHitos({ proyecto: proyecto.id });


    return <>
        <List.Item style={{ padding: "0", border: "0", marginTop: "4rem"}} >
            <List.Item.Meta
                title={
                    <>
                        <span className="nombreProyectoLista">{proyecto.nombre}</span>
                        { proyecto.redbooth_id &&
                        <Button
                            type="link"
                            size="small"
                            href={`https://redbooth.com/a/index.html#!/projects/${proyecto.redbooth_id}/tasks`}
                            target="_blank"
                            rel="noopener noreferrer"
                            title="Ver en Redbooth"
                            style={{ color: "#1890ff" }}
                        >
                            <FontAwesomeIcon icon={['fab', 'trello']} />
                        </Button>
                        }
                    </>
                }
                description={
                    <>
                        { (loadingTasks || loadingHitos) && <Skeleton /> }
                        {
                            tasks && hitos &&
                            <Descriptions
                                bordered
                                size="small"
                                labelStyle={{ width: "15rem" }}
                            >
                                <Descriptions.Item label="Tareas incompletas" span={3} contentStyle={{ paddingLeft: "1.3rem" }}>{tasks.length}</Descriptions.Item>

                                <Descriptions.Item label="Hitos" span={3} contentStyle={{ paddingLeft: "1.3rem" }}>
                                    <ListaHitosProyecto hitos={hitos} proyecto={proyecto} />
                                </Descriptions.Item>

                                <Descriptions.Item label="Actividad en las tareas en los últimos 30 días" span={3}>
                                    <ListaUltimasTareasProyecto tasks={tasks} />
                                </Descriptions.Item>

                            </Descriptions>

                        }
                    </>
                }
            />
        </List.Item>
    </>;
};

export default FilaProyecto;