import React, {useState} from "react";
import {Alert, Button, Steps, Spin, Descriptions} from "antd";
import {postImportRedboothData} from "../../helpers/api/postImportRedboothData";

import { SwapOutlined, SettingOutlined, ApiOutlined, CheckCircleOutlined } from '@ant-design/icons';
import {ConfigRedboothApi} from "./ConfigRedboothApi";
import AuthRedbooth from "./AuthRedbooth";
import {useRouteMatch} from "react-router-dom";
import {useSelector} from "react-redux";

const { Step } = Steps;

const Redbooth = ({ userGoogle }) => {

    const userApp = useSelector(state => state.userApp.userApp);
    const { token } = userApp;

    const [ importandoRedbooth, setImportandoRedbooth ] = useState(false);
    const [ errorImportandoRedbooth, setErrorImportandoRedbooth ] = useState(false);
    const [ resultadoImportacionRedbooth, setResultadoImportacionRedbooth ] = useState(false);

    const autorizarRedbooth = () => {
        const redboothClientId = '35d2db082a2c822f64c3c7823db55bdbb0847e21b9ee0a8dd494f530e1de7c64';
        const redirect_uri = window.location.origin + '/configuration/auth-redbooth/';
        const url = `https://redbooth.com/oauth2/authorize?response_type=code&client_id=${redboothClientId}&redirect_uri=${redirect_uri}`;
        window.open(url, "_self");
    }

    const importarDatosRedbooth = () => {
        setImportandoRedbooth(true);
        postImportRedboothData({
            callback: ({ error, result }) => {
                setImportandoRedbooth(false);
                if(error){
                    setErrorImportandoRedbooth(error);
                } else {
                    setResultadoImportacionRedbooth(result);
                }
            },
            token
        })
    }

    const { path } = useRouteMatch();

    let currentPosStep = 0;
    if(!userApp.redbooth){
        currentPosStep = 1;
    } else {
        currentPosStep = 2;
    }

    if(userApp.syncUser !== 1){
        currentPosStep = 1;
    }

    return (
        <>
            { (userApp.syncUser === 1) &&
                <>
                    <Steps current={currentPosStep} style={{ marginBottom: '1rem', maxWidth: "50%" }} size="small" >
                        { userApp.syncUser === 1 && <Step title="Configuración" description="" icon={(<SettingOutlined /> || <CheckCircleOutlined style={{ color: '#52c41a'}} />)} /> }
                        <Step title="Conectar con Redbooth" description="" icon={(!userApp.redbooth && <ApiOutlined />) || <CheckCircleOutlined style={{ color: '#52c41a'}} />} />
                        { userApp.syncUser === 1 && <Step title="Sincronización" description="" icon={<SwapOutlined />}  /> }
                    </Steps>
                </>
            }


            { errorImportandoRedbooth && <Alert message="Error importando datos de Redbooth, contacte con el administrador" type="error" style={{ marginBottom: "1rem" }} showIcon />}

            {/*  CONFIGURACION  */}
            { userApp.syncUser === 1 && <ConfigRedboothApi userGoogle={userGoogle} /> }

            {/*  AUTORIZACION  */}
            { currentPosStep === 1 && (path !== '/configuration/auth-redbooth/') &&
                <>
                    { !userApp.redbooth &&
                        <Alert
                            message="Acceso a Redbooth"
                            description={<>
                                <p>Conecta con tu cuenta de Redbooth para sincronizar datos y poder usar las opciones de tareas sincronizadas.</p>
                                <Button type="primary" onClick={autorizarRedbooth} >Conectar ahora</Button>
                            </>}
                            type="info"
                            showIcon
                            icon={<ApiOutlined />}
                            style={{ marginBottom: "1rem" }}
                        />
                    }
                    { userApp.redbooth &&
                        <Alert
                            message="Tu cuenta está conectada con Redbooth"
                            description="Ahora puedes usar las opciones de las tareas sincronizadas con Redbooth"
                            type="success"
                            showIcon
                            icon={<CheckCircleOutlined />}
                            style={{ marginBottom: "1rem" }}
                        />
                    }
                </>
            }

            {/*  AUTORIZACION REDIRECT  */}
            { (path === '/configuration/auth-redbooth/') && !userApp.redbooth && <AuthRedbooth userGoogle={userGoogle} />}

            {/*  IMPORTAR DATOS  */}
            { userApp.administrador && userApp.redbooth &&
                <>
                    <h2 >Importar datos desde Redbooth</h2>
                    <p>
                        WorkEcommium realiza la sicronización de datos de manera automática.<br />
                        Si lo deseas puedes realizar una sincronización ahora manualmente.
                    </p>
                    <Button
                        type="primary"
                        disabled={ importandoRedbooth || errorImportandoRedbooth }
                        onClick={!importandoRedbooth && !errorImportandoRedbooth && importarDatosRedbooth}
                        danger={errorImportandoRedbooth}
                        icon={<SwapOutlined />}
                    >Importar ahora</Button>

                    { importandoRedbooth && <><Spin size="small" style={{ marginLeft: "1rem" }} /><span style={{ color: "#1890ff", marginLeft: "0.5rem" }}>Sincronizando datos...</span></>}

                    { resultadoImportacionRedbooth &&
                        <div style={{ marginTop: "2rem", maxWidth: "50%" }}>
                            <Descriptions title={<span style={{ color: '#52c41a'}}><CheckCircleOutlined /> Sincronización finalizada correctamente</span>}  >
                                <Descriptions.Item label="Organizaciones">{resultadoImportacionRedbooth.organizaciones.new.length}</Descriptions.Item>
                                <Descriptions.Item label="Proyectos">{resultadoImportacionRedbooth.proyectos.new.length}</Descriptions.Item>
                                <Descriptions.Item label="Usuarios">{resultadoImportacionRedbooth.usuarios.new.length}</Descriptions.Item>
                                <Descriptions.Item label="Tareas">{resultadoImportacionRedbooth.tareas.new.length}</Descriptions.Item>
                            </Descriptions>
                        </div>
                    }

                </>

            }



        </>
    );
}

export default Redbooth;