
export const baseUrl = window.location.protocol + "//" + window.location.hostname +  ":9000";

export const fetchHelper = ({ url, method = 'GET', query = {}, data = {}, callback = () => null, token }) => {

    let cancelado = false;
    const urlObject = new URL(url);
    const init = { method };

    if(Object.keys(query).length > 0){
        const params = Object.keys(query).map(key => [key, query[key]]);
        urlObject.search = new URLSearchParams(params).toString();
    }

    const headers = {
        'x-token': token
    }

    if(Object.keys(data).length > 0){
        init.body = JSON.stringify(data);
        headers['Content-Type'] = 'application/json';
    }

    init.headers = headers;


    const promiseFetch = fetch( urlObject, init )
        .then(response => {
            if(cancelado){
                callback({ error: "Canceled", result: null });
            } else {
                if(response.ok){
                    response.json().then(data => {
                        const { error, result } = data;
                        callback({ error, result });
                    });
                } else {
                    callback({
                        error: {
                            status: response.status,
                            statusText: response.statusText
                        },
                        result: null
                    });
                }
            }
        }).catch(e => {
            callback({ error: e, result: null });
    });

    return {
        promise: promiseFetch,
        cancel: () => {
            cancelado = true;
        }
    }
}