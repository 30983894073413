import React from "react";

import {Alert, PageHeader, Skeleton, Tag} from "antd";
import Tasks from "./Tasks";
import {useFetchUser} from "../hooks/useFetchUser";

const User = ({ userApp, userId = null }) => {

    const { data: user, loading: loadingUser, error: errorUser } = useFetchUser({ userId });

    return <div>
        { loadingUser && <Skeleton /> }
        { errorUser && <Alert message="Ha ocurrido un error, contacte con el administrador" type="error" style={{ marginBottom: "1rem" }} showIcon />}
        { user && !errorUser &&
            <>
                <PageHeader
                    title={<>{user.first_name} {user.last_name}</>}
                    subTitle={user.email}
                    tags={[
                        ((user.administrador) && <Tag key="administrador" color="blue">Administrador</Tag>)
                    ]}
                    avatar={{ src: user.profile_avatar_url }}
                >
                    <Tasks userApp={userApp} user={user} style={{ marginTop: "2rem" }} />
                </PageHeader>
            </>
        }


    </div>;
}

export default User;