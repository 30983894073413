import React, {useCallback} from "react";
import { Form, Input, Button } from 'antd';
import {putNewDepartamento} from "../helpers/api/putNewDepartamento";


const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const tailLayout = {
    wrapperCol: { offset: 6, span: 14 },
};

const FormularioNuevoDepartamento = ({ userApp, cerrarModal }) => {

    const { token } = userApp;

    const onFinish = useCallback(values => {
        const { departamento } = values;
        putNewDepartamento({
            departamento,
            token,
            callback: ({ error, result }) => {
                if(!error){
                    cerrarModal();
                }
            }
        });

    }, [token, cerrarModal]);

    return (
        <Form
            {...layout}
            name="basic"
            initialValues={{}}
            onFinish={onFinish}
        >
            <Form.Item
                label="Departamento"
                name="departamento"
                rules={[{ required: true, message: '' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Crear
                </Button>
            </Form.Item>
        </Form>
    );
}

export default FormularioNuevoDepartamento;