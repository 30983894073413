import {useFetch} from "./useFetch";
import {baseUrl} from "../helpers/fetchHelper";
import {useSelector} from "react-redux";

export const useFetchTasks = ({ uid = null, proyecto = null, desde = null }) => {

    const { token } = useSelector(state => state.userApp.userApp);
    const ultimaActualizacion = useSelector(state => state.ultimaActualizacion.ultimaActualizacion);

    const query = {}, data = {};
    let url = "";
    url = `${baseUrl}/api/tareas`;

    if(uid){ query.uid = uid };
    if(desde){ query.desde = desde };
    if(proyecto){ query.proyecto = proyecto };

    return useFetch( url, { query, data }, ultimaActualizacion, token );
}
