import React, {useState} from "react";
import {Button, List, Modal, Tag} from "antd";
import Task from "../secciones/Task";
// import {useFetchTasks} from "../hooks/useFetchTasks";
// import moment from "moment";

const ListaUltimasTareasProyecto = ({ tasks }) => {



    const [idTaskSelected, setIdTaskSelected] = useState(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => setIsModalVisible(true);
    const handleClose = () => setIsModalVisible(false);

    const abrirTarea = (tarea) => {
        setIdTaskSelected(tarea.id);
        showModal();
    }

    return <>
        {
            tasks.length > 0 &&
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={tasks}
                bordered={false}
                renderItem={ tarea => <List.Item style={{ padding: "0", border: "0"}} >
                    <List.Item.Meta
                        title={
                            <>
                                <Tag >00:00</Tag>
                                <Button type="link" size="small" onClick={() => abrirTarea(tarea)} style={{ paddingLeft: 0 }} >
                                    {tarea.titulo}
                                </Button>
                            </>
                        }
                    />
                </List.Item> }
            />
        }

        {
            tasks.length === 0 && "Sin actividad en los ultimos 30 días"
        }
        <Modal visible={isModalVisible} onCancel={handleClose} footer={null} width="none" style={{maxWidth: "70rem"}} >
            {
                idTaskSelected && isModalVisible
                && <Task taskId={idTaskSelected} />
            }
        </Modal>
    </>;
};

export default ListaUltimasTareasProyecto;