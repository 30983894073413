import React from "react";
import {Divider, Tag} from "antd";
import Redbooth from "../components/redbooth/Redbooth";
import {useSelector} from "react-redux";



const Configuration = ({ userGoogle }) => {

    const userApp = useSelector(state => state.userApp.userApp);

    return <div>
        <div style={{ marginBottom: "3rem"}}>
            <h2 >Mi perfil</h2>
            <p>
                Nombre: { userApp.first_name } { userApp.last_name } { (userApp.administrador) && <Tag key="administrador" color="blue">Administrador</Tag> }<br />
                Email: {userApp.email}<br />
                { userApp.redbooth_id &&
                    <>Perfil Redbooth: <a href={`https://redbooth.com/a/#!/apps/reporting/tasks?filter_user_id=${userApp.redbooth_id}`} target="_blank" rel="noopener noreferrer">Ver perfil en Redbooth</a><br /></>
                }
            </p>
        </div>
        <Divider />

        <div style={{ marginBottom: "3rem"}}>
            <h2>Conectar con Redbooth</h2>
            <Redbooth userGoogle={userGoogle} />
        </div>
        <Divider />

        <div>
            <h2 >Acerca de WorkEcommium</h2>
            <p>
                WorkEcommium es un sistema desarrollado por Ecommium.<br />
            </p>
        </div>
    </div>;
}

export default Configuration;