import React from 'react'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Redirect
} from "react-router-dom";
import { PrincipalLayout } from "./PrincipalLayout";
import Dashboard from "./secciones/Dashboard";
import NotFound404 from "./secciones/NotFound404";
import Tasks from "./secciones/Tasks";
import Task from "./secciones/Task";
import Users from "./secciones/Users";
import Configuration from "./secciones/Configuration";
import User from "./secciones/User";
import Projects from "./secciones/Projects";

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'
library.add(fab, far, fas);




export const App = () => {

    return (
        <Router>
            <Switch>

                <Route path="/tasks/" exact >
                    <PrincipalLayout getSection={params =>
                        <Tasks {...params} />}
                    />
                </Route>

                <Route path="/tasks/:taskId/" exact >
                    <PrincipalLayout getSection={params =>
                        <Task {...params} />}
                    />
                </Route>


                <Route path="/users/" exact >
                    <PrincipalLayout getSection={params =>
                        <Users {...params} />}
                    />
                </Route>

                <Route path="/users/:userId/" exact >
                    <PrincipalLayout getSection={params =>
                        <User {...params} />}
                    />
                </Route>

                <Route path="/projects/" exact >
                    <PrincipalLayout getSection={params =>
                        <Projects {...params} />}
                    />
                </Route>

                <Route path="/configuration/" exact >
                    <PrincipalLayout getSection={params =>
                        <Configuration {...params} />}
                    />
                </Route>

                <Route path="/configuration/auth-redbooth/" exact >
                    <PrincipalLayout getSection={params =>
                        <Configuration {...params} />}
                    />
                </Route>

                <Route path="/" exact >
                    <PrincipalLayout getSection={params =>
                        <Dashboard {...params} />}
                    />
                </Route>

                <Route path="/" exact >
                    <Redirect to="/" />
                </Route>

                <Route >
                    <NotFound404 />
                </Route>

            </Switch>
        </Router>
    )
}
