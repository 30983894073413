import React from "react";
import {PlayCircleFilled} from "@ant-design/icons";
import {Alert, Typography} from "antd";
import TimeTaskLine from "../utils/TimeTaskLine";
import {useSelector} from "react-redux";

const { Text } = Typography;

const TareaEnCurso = () => {

    const taskOnPlay = useSelector(state => state.taskOnPlay.taskOnPlay);

    return <>
        <Alert
            message={<TimeTaskLine task={taskOnPlay} showIcon={false} />}
            description={taskOnPlay.description || <Text disabled>Sin descripción</Text>}
            type="info"
            showIcon
            icon={<PlayCircleFilled  className="animacionParpadeoOnPlay" />}
        />
    </>;
};

export default TareaEnCurso;