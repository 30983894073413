import React, { useState } from "react";
import {Button, Modal, Tag} from "antd";
import { PlayCircleFilled } from '@ant-design/icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import moment from "moment";
import TagTime from "./TagTime";
import Task from "../../secciones/Task";
import {getMomentDatetime, isTaskOnPlay} from "../../helpers/utils";


const TimeTaskLine = ({ task, showIcon = true, style = {} }) => {

    const [isModalVisible, setIsModalVisible] = useState(false);
    const showModal = () => setIsModalVisible(true);
    const handleClose = () => setIsModalVisible(false);

    const isOldTaskOnPlay = task => {
        const tiempoOnPlay = task.tiempos.find(t => t.fin === null);
        if(tiempoOnPlay){
            return moment().format('YYYYMMDD') !== getMomentDatetime(tiempoOnPlay.inicio).format('YYYYMMDD');
        }
        return false;
    }

    const getMinutesOnPlay = task => {
        const minutesArray = task.tiempos.map( t => {
            if(t.fin == null){
                const end = moment();
                const start = getMomentDatetime(t.inicio);
                if(end.format('YYYYMMDD') !== start.format('YYYYMMDD')){
                    return 0; // Se quedo abierta la tarea desde un dia anterior
                }

                return parseInt(moment.duration(end.diff(start)).asMinutes());
            }

            return t.minutos;

        });

        const totalMinutos = minutesArray.reduce((a, b) => a + b, 0);
        return totalMinutos;
    }

    const getMinutesOnPlayToday = task => {
        const timesToday = task.tiempos.filter( t => moment().format('YYYYMMDD') === getMomentDatetime(t.inicio).format('YYYYMMDD') );


        const minutesArray =  timesToday.map( t => {
            if(!t.fin){
                const end = moment();
                const start = getMomentDatetime(t.inicio);
                return parseInt(moment.duration(end.diff(start)).asMinutes());
            }
            return t.minutos;
        });

        const totalMinutos = minutesArray.reduce((a, b) => a + b, 0);
        return totalMinutos;
    }

    const getTimeFromMinutes = minutes => {
        const hoursTime = parseInt(minutes / 60);
        const minutesTime = minutes - (hoursTime * 60);

        return {
            hours: hoursTime.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false }),
            minutes: minutesTime.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
        };
    }

    const isOnPlay = isTaskOnPlay(task);

    const olvidoCerrarTarea = isOnPlay && isOldTaskOnPlay(task);
    const dateOlvidoCerrarTarea = olvidoCerrarTarea && getMomentDatetime(task.tiempos.find(t => t.fin == null).inicio).format("DD-MM-YYYY");

    const minutesOnPlay = getMinutesOnPlay(task);
    const time = getTimeFromMinutes(minutesOnPlay);

    const minutesOnPlayToday = getMinutesOnPlayToday(task);
    const timeToday = getTimeFromMinutes(minutesOnPlayToday);

    return <>
        <span style={style}>
            {
                olvidoCerrarTarea &&
                <>
                    { showIcon && <PlayCircleFilled style={{ marginRight: 6, color: "#ff4d4f" }}/> }
                    <Tag color="#ff4d4f" >{dateOlvidoCerrarTarea} Tarea no cerrada</Tag>
                </>
            }
                { isOnPlay && !olvidoCerrarTarea &&
                <>
                    { showIcon && <PlayCircleFilled className="animacionParpadeoOnPlay" style={{ marginRight: 6, color: "#1890ff" }}/> }
                    <TagTime time={timeToday} color="#108ee9" texto="" onPlay title="Tiempo activo hoy" />
                </>
                }

                { !isOnPlay && !olvidoCerrarTarea && minutesOnPlayToday > 0 &&
                <>
                    <TagTime time={timeToday} color="blue" texto="" title="Tiempo activo hoy" />
                </>
                }

            <Button size="small" href={`https://redbooth.com/a/index.html#!/projects/${task.projectRedboothId}/tasks`} target="_blank" rel="noopener noreferrer" >
                <>{task.proyecto.nombre}</>
            </Button>

            <Button type="link" size="small" style={{ fontWeight: isOnPlay? "bold": "normal" }} onClick={showModal}>
                <>{task.titulo}</>
            </Button>

            <Button type="link" size="small"  href={`/tasks/${task.id}`} target="_blank" rel="noopener noreferrer" title="Abrir en nueva pestaña" style={{ paddingLeft: 0}} >
                <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
            </Button>

            <Button type="link" size="small"  href={`https://redbooth.com/projects/${task.projectRedboothId}/tasks/${task.redbooth_id}`} target="_blank" rel="noopener noreferrer" title="Ver en Redbooth" style={{ paddingLeft: 0}} >
                <FontAwesomeIcon icon={['fab', 'trello']} />
            </Button>

            { task.tiempos.length > 0 && <TagTime time={time} texto="Total" onPlay={isOnPlay} title="Tiempo activo total" />}

        </span>

        <Modal visible={isModalVisible} onCancel={handleClose} footer={null} width="none" style={{maxWidth: "70rem"}} >
            { isModalVisible
                && <Task taskId={task.id} />
            }
        </Modal>
    </>;

}

export default TimeTaskLine;