import React, {useState} from "react";
import {Alert, Button, Divider, List, Skeleton, Typography} from "antd";
import FilaTiempoTarea from "../components/filas/FilaTiempoTarea";
import moment from "moment"

import { PlusOutlined, CaretRightOutlined } from '@ant-design/icons';
import CrearRegistroTiempo from "../components/alertas/CrearRegistroTiempo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useFetchTask} from "../hooks/useFetchTask";
import {useSelector} from "react-redux";
import {getMomentDatetime} from "../helpers/utils";
import {postPlayTask} from "../helpers/api/postPlayTask";

const { Text } = Typography;

const Task = ({ taskId, style = {} }) => {

    const userApp = useSelector(state => state.userApp.userApp);
    const { token } = userApp;

    const [ anadiendoNuevoRegistro, setAnadiendoNuevoRegistro ] = useState(false );

    const { data: task, loading: loadingTask, error: erroTask } = useFetchTask({ taskId });

    const isTaskOnPlay = task && task.tiempos.some( t => !t.fin );

    const putOnPlay = () => {
        postPlayTask({
            taskId,
            token
        });
    }


    return <div style={style}>
        { loadingTask && <Skeleton /> }
        { erroTask && <Alert message="Ha ocurrido un error, contacte con el administrador" type="error" style={{ marginBottom: "1rem" }} showIcon />}
        { task && !erroTask &&
            <>
                <h1 style={{ fontWeight: "bold" }}>
                    {task.titulo}
                    <Button
                        type="link"
                        size="small"
                        href={`/tasks/${task.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Abrir en nueva pestaña"
                        style={{ marginLeft: 6 }}
                    >
                        <FontAwesomeIcon icon={['fas', 'external-link-alt']} />
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        href={`https://redbooth.com/projects/${task.projectRedboothId}/tasks/${task.redbooth_id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        title="Ver en Redbooth"
                        style={{ paddingLeft: 0}}
                    >
                        <FontAwesomeIcon icon={['fab', 'trello']} />
                    </Button>
                </h1>
                <div style={{ marginBottom: "3rem"}} >
                    {task.descripcion &&
                        <div dangerouslySetInnerHTML={{ __html: task.descripcion_html || task.descripcion }} />
                    }
                    {!task.descripcion && <Text disabled>Sin descripción</Text>}
                </div>


                <Divider />
                <div style={{ marginBottom: "3rem"}} >
                    <h2>Última actividad</h2>
                    <div>
                        { !isTaskOnPlay && <Button type="primary" ghost icon={<CaretRightOutlined />} style={{ marginRight: 6 }} onClick={putOnPlay} >Iniciar</Button> }
                        { !anadiendoNuevoRegistro? <Button type="default" icon={<PlusOutlined />} onClick={() => setAnadiendoNuevoRegistro(true)} >Añadir un registro</Button> : null }
                        { anadiendoNuevoRegistro? <CrearRegistroTiempo userApp={userApp} taskId={taskId} onCancel={() => setAnadiendoNuevoRegistro(false)} onSave={() => setAnadiendoNuevoRegistro(false)} /> : null }
                    </div>

                    { !task.tiempos.length?
                        <div style={{ marginTop: "1rem" }}>
                            <Text disabled >Aun no hay actividad en esta tarea</Text>
                        </div>
                        : <List
                            style={{ marginTop: "2rem", paddingLeft: "1rem"}}
                            itemLayout="horizontal"
                            dataSource={
                                task.tiempos.sort((t1, t2) => {
                                    const m1 = t1.fin ? getMomentDatetime(t1.fin) : moment();
                                    const m2 = t2.fin ? getMomentDatetime(t2.fin) : moment();
                                    return m2.format('YYYYMMDDHHmm') - m1.format('YYYYMMDDHHmm');
                                })
                            }
                            renderItem={ time => <FilaTiempoTarea userApp={userApp} time={time} task={task} /> }
                        />
                    }

                </div>


            </>
        }



    </div >;
}

export default Task;