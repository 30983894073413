import {useFetch} from "./useFetch";
import {baseUrl} from "../helpers/fetchHelper";
import {useSelector} from "react-redux";

export const useFetchHitos = ({ proyecto }) => {

    const userApp = useSelector(state => state.userApp.userApp);
    const ultimaActualizacion = useSelector(state => state.ultimaActualizacion.ultimaActualizacion);

    let token = '';
    let url = '';
    if(userApp){
        token = userApp.token;
        url = `${baseUrl}/api/hitos`;
    }

    return useFetch(url, { query: { proyecto } }, ultimaActualizacion, token );
}
