import React from "react";
import {Button, List, Typography} from "antd";
import TimeTaskLine from "../utils/TimeTaskLine";
import {BorderOutlined, CaretRightOutlined, CheckSquareOutlined, PauseOutlined} from "@ant-design/icons";
import { putCompleteTask } from "../../helpers/api/putCompleteTask";
import { putIncompleteTask } from "../../helpers/api/putIncompleteTask";
import { postPlayTask } from "../../helpers/api/postPlayTask";
import { putStopTasks } from "../../helpers/api/putStopTasks";
import {useSelector} from "react-redux";
import {isTaskOnPlay} from "../../helpers/utils";

const { Text } = Typography;

const FilaTarea = ({ task, user, showDescription = true, showButton, showAsSubitle = false }) => {

    const userApp = useSelector(state => state.userApp.userApp);
    const { token } = userApp;

    const isTheUser = !user || parseInt(user.id) === userApp.id;

    const putOnPlay = task => {
        postPlayTask({
            taskId: task.id,
            token
        });
    }

    const putOnPause = task => {
        putStopTasks({ token });
    }

    const putComplete = task => {
        putCompleteTask({
            taskId: task.id,
            token
        });
    }

    const putInComplete = task => {
        putIncompleteTask({
            taskId: task.id,
            token
        });
    }

    const getButtonPlayPause = task => {
        const isCompleted = task.completo;
        const isOnPlay = isTaskOnPlay(task);
        return <>
            { !isCompleted && <Button type="text" icon={ <BorderOutlined />} size="small" onClick={ () => putComplete(task) } /> }
            { isCompleted && <Button type="text" icon={ <CheckSquareOutlined />} style={{ color: "#52c41a" }} size="small" onClick={ () => putInComplete(task) } /> }

            { !isCompleted && isTheUser && !isOnPlay && <Button type="default" icon={ <CaretRightOutlined />} size="small" style={{ marginLeft: '0.5rem' }} onClick={ () => putOnPlay(task) } /> }
            { !isCompleted && isTheUser && isOnPlay && <Button type="primary" icon={<PauseOutlined />} size="small" style={{ marginLeft: '0.5rem' }} onClick={ () => putOnPause(task) }  /> }
        </>
    }

    return <List.Item className={ `elementoListaTarea ${ (isTaskOnPlay(task) && 'onPlay') || ''} ${(showAsSubitle && 'showAsSubitle') || ''}` } >
        <List.Item.Meta
            avatar={showButton? getButtonPlayPause(task) : null}
            title={<>
                <TimeTaskLine task={task} />
            </>}
            description={ showDescription ? (task.descripcion || <Text disabled>Sin descripción</Text>) : "" }
        />
    </List.Item>;
};

export default FilaTarea;