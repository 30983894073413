import React from "react";
import {CrownTwoTone} from "@ant-design/icons";
import {Alert, Typography} from "antd";

const { Text } = Typography;

const FelicidadesNoTienesTareas = () => {

    return <>
        <Alert
            message={<><Text strong>¡Felicidades!</Text> No tienes tareas pendientes</>}
            description={<>
                Ponte en contacto con tu administrador para que pueda asignarte tareas nuevas.
                <br />
                Puedes encontrar a tu administrador en la sección de <span style={{ fontWeight: "bold"}} >Usuarios</span>.
            </>}
            type="success"
            showIcon
            icon={<CrownTwoTone twoToneColor="#52c41a" />}
        />
    </>;
};

export default FelicidadesNoTienesTareas;