import React, {useState} from "react";
import { ExclamationCircleOutlined, PlayCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { Alert, Button, Form, TimePicker } from "antd";
import {putFinishTimeTask} from "../../helpers/api/putFinishTimeTask";
import {getMomentDatetime} from "../../helpers/utils";
import {useSelector} from "react-redux";

const HoraTareaEnCursoOlvidada = () => {

    const taskOnPlay = useSelector(state => state.taskOnPlay.taskOnPlay);
    const { token } = useSelector(state => state.userApp.userApp);

    const [ form ] = Form.useForm();
    const [ errorHoraTareaAntigua, setErrorHoraTareaAntigua ] = useState(null );
    const [ selectorAbierto, setSelectorAbierto ] = useState(false );

    const timeOnPlay = taskOnPlay.tiempos.find(t => !t.fin);

    const guardarHoraFinalizacionTareaAntigua = (data) => {

        if(data.fin.isBefore(timeOnPlay.inicio)){
            setErrorHoraTareaAntigua(<><CloseCircleFilled style={{ marginRight: 6}} />La hora selecciona es anterior a la hora de inicio.</>)
        } else {
            putFinishTimeTask({
                timeId: timeOnPlay.id,
                date: data.fin.utc().toDate(),
                callback: ({ error, result }) => {
                    if(error){
                        setErrorHoraTareaAntigua(<><CloseCircleFilled style={{ marginRight: 6}} />Error guardando la hora seleccionada.</>)
                    }
                },
                token
            });
        }
    }

    const descriptionOldTaskOnPlay =  () => {

        const config = {
            rules: [{ type: 'object', required: true, message: 'Please select time!' }],
        };

        return <>
            <div>
                <Form
                    layout="inline"
                    form={form}
                    onFinish={guardarHoraFinalizacionTareaAntigua}
                    className="formularioHoraTareaAntiguaAbierta"
                    initialValues={{
                        fin: getMomentDatetime(timeOnPlay.inicio)
                    }}
                >
                    <Form.Item>
                        <span style={{ fontWeight: "bold" }} >Inicio:</span> {getMomentDatetime(timeOnPlay.inicio).format("DD/MM/YYYY HH:mm")}
                    </Form.Item>

                    <Form.Item>
                        <span style={{ fontWeight: "bold" }} >Finalización:</span>
                    </Form.Item>

                    <Form.Item name="fin" {...config}>
                        <TimePicker
                            inputReadOnly
                            format="DD/MM/YYYY HH:mm"
                            showNow={false}
                            allowClear={false}
                            onOpenChange={(open) => setSelectorAbierto(open)}
                        />
                    </Form.Item>

                    <Form.Item >
                        <Button htmlType="submit" type="primary" size="small" style={{ marginLeft: 6 }} disabled={selectorAbierto} >Guardar hora de finalización</Button>
                    </Form.Item>

                    <Form.Item>
                        <span style={{ color: "#ff4d4f" }} >{errorHoraTareaAntigua}</span>
                    </Form.Item>

                </Form>

                <Alert
                    style={{ marginTop: "1rem" }}
                    message={<><span style={{ fontWeight: "bold"}}>Olvidaste detener el desarrollo de esta tarea</span></>}
                    description={
                        <div>
                            Es importante que detengas el tiempo de tus tareas antes de finalizar tu jornada laboral.
                            <br />
                            Por favor, indica a que hora finalizaste.
                        </div>
                    }
                    type="warning"
                    showIcon
                    icon={<ExclamationCircleOutlined style={{ color: "#faad14" }} />}
                />


            </div>
        </>
    }

    return <>
        <Alert
            style={{ marginBottom: "1rem" }}
            message={taskOnPlay.title}
            description={descriptionOldTaskOnPlay()}
            type="error"
            showIcon
            icon={<PlayCircleFilled  className="animacionParpadeoOnPlay" />}
        />
    </>;
};

export default HoraTareaEnCursoOlvidada;