import React from "react";
import {Avatar, Badge} from "antd";

import {getMomentDatetime} from "../../helpers/utils";

const AvatarUser = ({ user, ...params }) => {
    let title = `${ user.first_name } ${ user.last_name }`;

    if(user.online) {
        title = `${title} | Online`;
    } else {
        if(user.ultima_conexion){
            const fechaUltimaConexion = getMomentDatetime(user.ultima_conexion).format('DD-MM-YYYY HH:mm');
            title = `${title} | Ultima conexión: ${fechaUltimaConexion}`;
        }
    }
    return <>
        <Badge dot status={user.online? 'success' : 'default' } >
            <Avatar src={ user.profile_avatar_url } title={title} />
        </Badge>
    </>
}

export default AvatarUser;