import React, {useEffect, useState} from "react";
import { Tag } from "antd";

const TagTime = ({ texto, time, onPlay = false, showSeconds = false, ...params }) => {
    const [ horas, setHoras ] = useState(parseInt(time.hours));
    const [ minutos, setMinutos ] = useState(parseInt(time.minutes));
    const [ segundos, setSegundos ] = useState(0);


    useEffect( () => {
        let contador;
        if(onPlay){
            contador = setInterval(() => {
                if(segundos === 59){
                    setSegundos(0);
                    if(minutos === 59){
                        setMinutos(0);
                        setHoras(horas + 1);
                    } else {
                        setMinutos(minutos + 1);
                    }
                } else {
                    setSegundos(segundos + 1);
                }
            }, 1000)
        }


        return () => {
            if(onPlay) {
                clearInterval(contador);
            }
        }
    });

    return <Tag {...params} >
        {texto !== "" && `${texto}: ` }
        {horas.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
        :{minutos.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}
        { showSeconds? `:${segundos.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })}` : '' }

    </Tag>
}

export default TagTime;