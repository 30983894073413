import React, {useCallback} from "react";
import {List, Button, Badge, Tag, Modal} from "antd";
import { StarFilled } from "@ant-design/icons";
import AvatarUser from "../utils/AvatarUser";
// import ListaUltimasTareasUsuario from "../ListaUltimasTareasUsuario";
import moment from "moment";
import FormularioCambiarDepartamento from "../FormularioCambiarDepartamento";
import {getMomentDatetime} from "../../helpers/utils";
import {useSelector} from "react-redux";
import {useFetchDepartamentos} from "../../hooks/useFetchDepartamentos";

// const IconText = ({ icon, text, style = {} }) => (
//     <span style={style} >
//     {React.createElement(icon, { style: { marginRight: 8 } })}
//         {text}
//   </span>
// );

const FilaUsuario = ({ user, key }) => {

    const userApp = useSelector(state => state.userApp.userApp);
    const { data: departamentos, loading: loadingDepartamentos, error: errorDepartamentos } = useFetchDepartamentos();

    const cambiarDepartamento = useCallback(() => {
        if(!loadingDepartamentos && !errorDepartamentos && departamentos){
            const modal = Modal.info({
                icon: "",
                width: "none",
                style: {maxWidth: "35rem"},
                closable: true,
                content: <FormularioCambiarDepartamento userApp={userApp} user={user} cerrarModal={() => modal.destroy()} departamentos={departamentos} />,
                okButtonProps: {hidden: true},
                maskClosable: true
            });
        }
    }, [ userApp, loadingDepartamentos, errorDepartamentos, departamentos, user ]);

    // const getTaskOnPlay = user => {
    //     const isTaskOnPlay = task => {
    //         return (task.tiempos.length && task.tiempos.some(t => !t.fin));
    //     }
    //     const taskOnPlay = user.tasks.find(t => isTaskOnPlay(t)) || null;
    //     return taskOnPlay;
    // }
    //
    // const taskOnPlay = getTaskOnPlay(user);

    let fechaUltimaConexion = "";
    if(user.ultima_conexion){
        const hoy = moment();
        const mFechaUltimaConexion = getMomentDatetime(user.ultima_conexion);
        if(hoy.format("YYYYMMDD") === mFechaUltimaConexion.format("YYYYMMDD")){
            fechaUltimaConexion = "Última conexión hoy a las " + mFechaUltimaConexion.format('HH:mm');
        } else if(hoy.subtract(1, 'day').format("YYYYMMDD") === mFechaUltimaConexion.format("YYYYMMDD")){
            fechaUltimaConexion = "Última conexión ayer a las  " + mFechaUltimaConexion.format('HH:mm');
        } else {
            fechaUltimaConexion = "Última conexión el día " + mFechaUltimaConexion.format('DD-MM-YYYY HH:mm');
        }
    }

    return <List.Item key={key} >
        <List.Item.Meta
            avatar={<AvatarUser user={user} />}
            title={<>
                <Tag color={user.departamento ? "processing" : "" } style={{ marginRight: 0 }} onClick={cambiarDepartamento} >{user.departamento ? user.departamento.nombre : "Sin departamento" }</Tag>
                <Button type="text" size="small" href={`/users/${user.id}/`} >{ user.first_name } { user.last_name }</Button>
                { user.administrador && <StarFilled style={{ color: "#1890ff", marginRight: 6 }} title="Administrador" /> }
                { !user.online && user.ultima_conexion && <Badge status="default" className="fechaUltimaConexionUsuario" text={fechaUltimaConexion} />}
                { user.online && <Badge status="success" className="fechaUltimaConexionUsuarioOnline" text='Online' />}
            </>}
            // description={ [
            //     user.tasks.length === 0 && <IconText icon={ CrownFilled } text={`Sin tareas pendientes`} key="sintareas" style={{color: "#52c41a"} } />,
            //     user.tasks.length > 0 && <ListaUltimasTareasUsuario key={user.id} usuario={user} userApp={userApp} taskOnPlay={taskOnPlay} />,
            // ] }
        />
    </List.Item>;
};

export default FilaUsuario;