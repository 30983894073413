import React, {useState} from "react";
import {Alert, Avatar, Button, Steps, DatePicker, TimePicker} from "antd";
import moment from "moment";
import { putNewTime } from "../../helpers/api/putNewTime";

const { Step } = Steps;

const CrearRegistroTiempo = ({ userApp, taskId, onCancel, onSave }) => {


    const [ startDatetime, setStartDatetime ] = useState(moment());
    const [ stopDatetime, setStopDatetime ] = useState(moment());

    const establecerStartDate = (datetime) => {
        setStartDatetime(datetime);
        setStopDatetime(datetime);
    }

    const guardar = () => {
        putNewTime({
            taskId,
            startDatetime: startDatetime.format('YYYY-MM-DD HH:mm:00'),
            stopDatetime: stopDatetime.format('YYYY-MM-DD HH:mm:00'),
            callback: data => {
                setStartDatetime(moment());
                setStopDatetime(moment());
                onSave(data);
            }
        });
    }

    return <>
        <Alert
            style={{ marginTop: '1rem' }}
            message={<>
                <Steps>
                    <Step
                        icon={<Avatar src={ userApp.profile_avatar_url } title={ `${userApp.first_name} ${userApp.last_name}` } />}
                        status="wait"
                        title={<>
                            Hora inicio:{' '}
                            <DatePicker showTime showNow={false} value={startDatetime} format="DD/MM/YYYY HH:mm" onOk={establecerStartDate} />
                        </>}
                    />

                    <Step
                        status="wait"
                        icon={<></>}
                        title={
                            <span style={{ width: "17rem", display: "inline-block" }}>
                                Hora fin:{' '}
                                <TimePicker min showNow={false} value={stopDatetime} format="DD/MM/YYYY HH:mm" onOk={setStopDatetime} />
                            </span>
                        }
                    />

                </Steps>
            </>}
            type="info"
            action={[
                <Button key="anadir" size="small" type="primary" onClick={guardar} style={{ marginRight: 6 }} >
                    Añadir
                </Button>,
                <Button key="cancelar" size="small" type="default" onClick={onCancel} >
                    Cancelar
                </Button>
            ]}
        />
    </>;
};

export default CrearRegistroTiempo;