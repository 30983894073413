import {useEffect, useState} from "react";

export const useFetch = ( url, init = {}, ultimaActualizacion = null, token = '' ) => {

    const [state, setState] = useState({ data: null, loading: true, error: null });

    let urlObject = null;
    if(url !== ''){
        urlObject = new URL(url);

        if(init.query && Object.keys(init.query).length > 0){
            const params = Object.keys(init.query).map(key => [key, init.query[key]]);
            urlObject.search = new URLSearchParams(params).toString();
        }

        const data = init.data || {};

        const headers = {
            'x-token': token
        }
        if( Object.keys(data).length > 0 ){
            headers['Content-Type'] = 'application/json';
        }

        init = {
            method: "GET",
            body: Object.keys(data).length > 0 ? JSON.stringify(init.data ): undefined,
            headers,
            ...init
        };
    }

    useEffect(() => {
        if(urlObject === null) {
            return;
        }

        fetch( urlObject, init)
            .then( resp => {
                if(resp.ok){
                    return resp.json();
                }

                return {
                    error: {
                        status: resp.status,
                        statusText: resp.statusText
                    }
                };

            } )
            .then( data => {
                const { result = null, error = null } = data;
                setState({
                    loading: false,
                    error,
                    data: result
                })
            })
            .catch(e => {
                setState({
                    loading: false,
                    error: e,
                    data: null
                })
            })
    }, [url, ultimaActualizacion]);

    return state;
}