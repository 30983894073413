import React, {useEffect, useState} from "react";
import {List, Alert, Switch, Skeleton} from 'antd';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import { putOrderTasks } from "../helpers/api/putOrderTasks";

import arrayMove from 'array-move';
import FilaTarea from "../components/filas/FilaTarea";
import FelicidadesNoTienesTareas from "../components/alertas/FelicidadesNoTienesTareas";
import EstupendoUsuarioSinTareas from "../components/alertas/EstupendoUsuarioSinTareas";
import { useFetchTasks } from "../hooks/useFetchTasks";
import { useSelector } from "react-redux";


const SortableContainer = sortableContainer(({children}) => {
    return <>{children}</>;
});
const SortableItem = sortableElement(({ value }) => <>{value}</>);


const Tasks = ({ user = null, style = {} }) => {

    const userApp = useSelector(state => state.userApp.userApp);

    const isTheUser = !user || parseInt(user.id) === userApp.id;
    const [ verCompletadas, setVerCompletadas ] = useState(false );
    const [ orderedTasks, setOrderedTasks ] = useState([] );

    const { data: tasks, loading: loadingTasks, error: errorTasks } = useFetchTasks({ uid: user ? user.uid : userApp.uid });

    useEffect( () => {
        let tasksArray = tasks || [];
        tasksArray = verCompletadas? tasksArray : tasksArray.filter( t => t.completo === false );
        tasksArray = tasksArray.sort((a,b) => a.posicion - b.posicion );
        setOrderedTasks(tasksArray);

    }, [ tasks, verCompletadas ])

    const onSortEnd = ({ oldIndex, newIndex, ...a }, ...b) => {
        if(oldIndex !== newIndex){
            const tareas = verCompletadas? tasks : tasks.filter( t => t.completo === false );
            const tareasReordenadas = arrayMove([ ...orderedTasks ], oldIndex, newIndex);
            setOrderedTasks(tareasReordenadas);

            guardarOrdenTareas({ taskId: tareas[oldIndex].id, pos: newIndex + 1 });
        }
    };

    const guardarOrdenTareas = ({ taskId, pos }) => {
        putOrderTasks({
            userId: user? user.id : userApp.id,
            taskId,
            pos
        });
    }

    const getFilaTarea = ({ task }) => {
        const showButton = (isTheUser || userApp.administrador);
        return <FilaTarea task={task} showButton={showButton} user={user} ></FilaTarea>;
    };

    return (
        <>
            { loadingTasks && <Skeleton /> }
            { errorTasks && <Alert message="Ha ocurrido un error, contacte con el administrador" type="error" style={{ marginBottom: "1rem" }} showIcon />}
            {
                !loadingTasks && !errorTasks
                && <div style={style}>
                    <h1 >Tareas</h1>

                    { user === null && <>
                        <p >
                            Realiza las tareas comenzando por la tarea superior y descendiendo por el listado a medida que las vayas resolviendo.
                            <br />
                            La prioridad de realizacion de las tareas esta indicada por el orden en que aparecen en el listado.
                            <br />
                            Solo tu administrador y tú pueden establecer o modificar el orden de tus tareas.
                            <br />
                            Ordena tus tareas moviendo la fila, usa el icono para arrastrarla a la posición que desees.
                        </p>

                    </> }



                    { isTheUser && tasks !== null && tasks.length === 0 &&
                    <FelicidadesNoTienesTareas />
                    }

                    { !isTheUser && tasks !== null && tasks.length === 0 &&
                    <EstupendoUsuarioSinTareas />
                    }
                    { ((user && user.redbooth_id) || (!user && userApp.redbooth_id)) &&
                    <a href={`https://redbooth.com/a/#!/apps/reporting/tasks?filter_user_id=${user? user.redbooth_id : userApp.redbooth_id}`} target="_blank" rel="noopener noreferrer" >
                        Ver en Redbooth
                    </a>
                    }

                    <div>
                        <Switch checked={verCompletadas} onChange={setVerCompletadas} /> Ver tareas completadas
                    </div>

                    { tasks !== null && tasks.length > 0 &&
                    <SortableContainer
                        onSortEnd={onSortEnd}
                        lockAxis="y"
                        helperClass="tareaEnMovimiento"
                        pressDelay={100}
                    >
                        <List
                            style={{ marginTop: "1rem" }}
                            dataSource={orderedTasks}
                            renderItem={(task, i) =>
                                <SortableItem
                                    value={ getFilaTarea({ task }) }
                                    key={`item-${task.id}`}
                                    index={i}
                                    disabled={task.completed === 1}
                                />
                            }
                        />
                    </SortableContainer>
                    }

                </div>
            }
        </>
    );
}

export default Tasks;