import React from "react";
import { Result, Button } from 'antd';

const NotFound404 = () => {
    return (
        <Result
            status="404"
            title="404"
            subTitle="Lo siento, la pagina que buscas no existe."
            extra={<Button type="primary" href="/">Ir a la pagina principal</Button>}
        />
    );
}

export default NotFound404;