import React, {useCallback} from "react";
import { Form, Input, Button } from 'antd';
import { putNewHito } from "../helpers/api/putNewHito";
import TextArea from "antd/es/input/TextArea";


const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const tailLayout = {
    wrapperCol: { offset: 6, span: 14 },
};

const FormularioNuevoHito = ({ proyecto, token, cerrarModal }) => {

    const onFinish = useCallback(values => {
        const { titulo, descripcion } = values;
        putNewHito({
            project_id: proyecto.id,
            titulo,
            descripcion,

            callback: ({ error, result }) => {
                if(!error){
                    cerrarModal();
                }
            },
            token
        });

    }, [token, proyecto, cerrarModal]);

    return (
        <Form
            {...layout}
            name="basic"
            initialValues={{}}
            onFinish={onFinish}
        >
            <Form.Item
                label="Titulo"
                name="titulo"
                rules={[{ required: true, message: '' }]}
            >
                <Input />
            </Form.Item>

            <Form.Item
                label="Descripcion"
                name="descripcion"
                rules={[{ required: true, message: '' }]}
            >
                <TextArea />
            </Form.Item>

            <Form.Item {...tailLayout}>
                <Button type="primary" htmlType="submit">
                    Crear
                </Button>
            </Form.Item>
        </Form>
    );
}

export default FormularioNuevoHito;