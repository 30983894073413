import React from "react";
import { List, Button, Modal, Popconfirm} from "antd";
// import { useFetchHitos } from "../hooks/useFetchHitos";
import {DeleteOutlined, PlusOutlined} from "@ant-design/icons";
import FormularioNuevoHito from "./FormularioNuevoHito";
import {useSelector} from "react-redux";
// import {getMomentDatetime} from "../helpers/utils";
import {deleteHito} from "../helpers/api/deleteHito";
// import Hito from "../secciones/Hito";

const ListaHitosProyecto = ({ hitos, proyecto }) => {

    const userApp = useSelector(state => state.userApp.userApp);
    const { token } = userApp;

    const abrirFormularioNuevoHito = () => {
        const modal = Modal.info({
            icon: "",
            width: "none",
            style: {maxWidth: "35rem"},
            closable: true,
            content: <FormularioNuevoHito proyecto={proyecto} token={token} cerrarModal={() => modal.destroy()} />,
            okButtonProps: {hidden: true},
            maskClosable: true
        });
    }

    const eliminarHito = (hito) => {
        deleteHito({
            hitoId: hito.id,
            token
        });
    }

    return <>
        <Button size="small" onClick={abrirFormularioNuevoHito} type="primary" ghost icon={<PlusOutlined />} >Añadir Hito</Button>
        {
            hitos.length > 0 &&
            <List
                size="small"
                itemLayout="horizontal"
                dataSource={hitos}
                bordered={false}
                style={{ marginTop: '1rem' }}
                renderItem={ hito => {
                    return <List.Item style={{ padding: "0", border: "0", marginBottom: 6 }} >
                        <List.Item.Meta
                            title={
                                <>
                                    <Button type="link" size="small" onClick={() => console.log('TODO - Ver informacion del hito en un modal')} style={{ paddingLeft: 0 }} >
                                        <>{hito.titulo}</>
                                    </Button>
                                    <Popconfirm
                                        title="¿Desea eliminar el hito?"
                                        onConfirm={() => eliminarHito(hito)}
                                        okText="Eliminar"
                                        cancelText="Cancelar"
                                        placement="rightTop"
                                        okButtonProps={{ danger: true }}
                                    >
                                        <Button danger size="small" type="link" icon={<DeleteOutlined />} title="Eliminar hito" />
                                    </Popconfirm>
                                </>
                            }
                        />
                    </List.Item>;
                }}
            />
        }

    </>;
};

export default ListaHitosProyecto;