import React from "react";
import {Alert, List, Skeleton} from "antd";
import FilaProyecto from "../components/filas/FilaProyecto";
import {useFetchProjects} from "../hooks/useFetchProjects";

const Projects = () => {
    const { data: proyectos, loading: loadingProjects, error: errorProjects } = useFetchProjects();
    return <>

        { !loadingProjects && !errorProjects
            && <div>
            { errorProjects && <Alert message="Ha ocurrido un error, contacte con el administrador" type="error" style={{ marginBottom: "1rem" }} showIcon />}
            <h1 >Proyectos</h1>
            { loadingProjects && <Skeleton />}
            { !loadingProjects && !errorProjects
                && <>
                    <p>
                        Estos son los proyectos en los que participa tu organización.
                    </p>

                    <List
                        style={{ marginTop: "2rem", paddingLeft: "1rem" }}
                        itemLayout="horizontal"
                        dataSource={proyectos}
                        renderItem={ proyecto => <FilaProyecto proyecto={proyecto} /> }
                    />
                </>
            }


        </div>
        }

    </>;
}

export default Projects;