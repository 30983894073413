import React from "react";
import  { GoogleLogout } from "react-google-login";
import { Button } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useDispatch } from "react-redux";
import { setUserApp } from "../../redux/actions/userApp";

const clientId = '607429418627-9barp8hp8e1m38rft0lorluq1funeet5.apps.googleusercontent.com';

const GoogleLogoutButton = () => {

    const dispatch = useDispatch();

    const onLogoutSuccess = () => {
        dispatch(setUserApp(null));
        window.open('/', "_self");
    }

    return (
        <GoogleLogout
            clientId={clientId}
            onLogoutSuccess={onLogoutSuccess}
            render={renderProps => <Button onClick={renderProps.onClick} disabled={renderProps.disabled}>Cerrar sesión<LogoutOutlined /></Button>}
        />
    );
}

export default GoogleLogoutButton;