import React, {useEffect, useState} from "react";

import { useLocation } from "react-router-dom";
import { postRedboothCode } from "../../helpers/api/postRedboothCode";
import {Alert, Button} from "antd";
import {useSelector} from "react-redux";

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

const AuthRedbooth = ({ userGoogle }) => {

    const userApp = useSelector(state => state.userApp.userApp);
    const { token } = userApp;

    const query = useQuery();
    const code = query.get("code");

    const [ error, setError ] = useState(null);
    const [ updatingRedboothCode, setUpdatingRedboothCode ] = useState(true);

    useEffect( () => {
        if(code){
            postRedboothCode({
                code: code,
                callback: ({ error, result }) => {
                    setUpdatingRedboothCode(false);
                    if(error){
                        if(error === 'Emails do not match'){
                            setError(`Usuario incorrecto. El email usado en redbooth debe ser ${userGoogle.email}`);
                        } else {
                            setError('Ha ocurrido un error conectando con Redbooth, pongase en contacto con el administrador.');
                        }
                    } else {
                        window.open("/configuration/", "_self");
                    }
                },
                token
            });
        } else {
            setUpdatingRedboothCode(false);
        }
    }, [token, code, userGoogle]);

    return (
        <div>
            {updatingRedboothCode && 'Conectando con Redbooth...'}
            {error && <Alert message={error} type="error" style={{ marginBottom: "1rem" }} showIcon />}
            {error && <Button href='/'>Volver</Button>}
        </div>
    );
}

export default AuthRedbooth;