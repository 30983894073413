import React from "react";
import {PlayCircleOutlined} from "@ant-design/icons";
import {Alert} from "antd";
import FelicidadesNoTienesTareas from "./FelicidadesNoTienesTareas";

// Api
import {useFetchTasks} from "../../hooks/useFetchTasks";
import {useSelector} from "react-redux";


const ContinuaSiguienteTarea = () => {

    const { uid } = useSelector(state => state.userApp.userApp);
    const { data: tasks, loading: loadingTasks, error: errorTasks } = useFetchTasks({ uid });
    const pendingTasks = tasks? tasks.filter( t => t.completo === false ).length : 0;

    return <>
        { !loadingTasks && !errorTasks && pendingTasks === 0 &&
            <FelicidadesNoTienesTareas />
        }
        { !loadingTasks && !errorTasks && pendingTasks > 0 &&
            <Alert
                message={<a href="/tasks/" >Continua con la siguiente tarea</a>}
                description={`Tienes ${pendingTasks.length} tareas asignadas`}
                type="success"
                showIcon
                icon={<PlayCircleOutlined />}
            />
        }

    </>;
};

export default ContinuaSiguienteTarea;