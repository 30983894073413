import React from "react";
import {Alert, Button, List, Modal, Skeleton, Popconfirm} from "antd";
import FilaUsuario from "../components/filas/FilaUsuario";
import {useFetchDepartamentos} from "../hooks/useFetchDepartamentos";
import {useFetchUsuarios} from "../hooks/useFetchUsuarios";
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import FormularioNuevoDepartamento from "../components/FormularioNuevoDepartamento";

import {deleteDepartamento} from "../helpers/api/deleteDepartamento";
import {useSelector} from "react-redux";


const Users = () => {

    const userApp = useSelector(state => state.userApp.userApp);
    const { token } = userApp;

    const { data: departamentos, loading: loadingDepartamentos, error: errorDepartamentos } = useFetchDepartamentos();
    const { data: usuarios, loading: loadingUsuarios, error: errorUsuarios } = useFetchUsuarios();

    const abrirNuevoDepartamento = () => {
        const modal = Modal.info({
            icon: "",
            width: "none",
            style: {maxWidth: "35rem"},
            closable: true,
            content: <FormularioNuevoDepartamento userApp={userApp} cerrarModal={() => modal.destroy()} />,
            okButtonProps: {hidden: true},
            maskClosable: true
        });
    }

    const eliminarDepartamento = (departamento) => {
        deleteDepartamento({
            departamentoId: departamento.id,
            token
        });
    }


    return <div>
        { (errorDepartamentos || errorUsuarios) && <Alert message="Ha ocurrido un error, contacte con el administrador" type="error" style={{ marginBottom: "1rem" }} showIcon />}
        <h1 >Usuarios</h1>
        <p >
            Este es el equipo de personas que forman parte de tu organizacion.
            <br />
            Aqui puedes ver en que están trabajando o conocer el estado de tus compañeros.
        </p>
        {
            (loadingDepartamentos || loadingUsuarios)
            && <Skeleton />
        }

        {
            !loadingDepartamentos && !errorDepartamentos
            && !loadingUsuarios && !errorUsuarios
            && <div>
                <Button type="primary" ghost icon={<PlusOutlined />} onClick={abrirNuevoDepartamento} >Nuevo departamento</Button>
            </div>
        }

        {
            !loadingDepartamentos && !errorDepartamentos
            && !loadingUsuarios && !errorUsuarios
            && departamentos
                .sort((a,b) => a.nombre.localeCompare(b.nombre))
                .map( d => (
                <List
                    header={<div>
                        {d.nombre}
                        {
                            userApp.administrador
                            && <>
                                <Popconfirm
                                    title="¿Desea eliminar el departamento?"
                                    onConfirm={() => eliminarDepartamento(d)}
                                    okText="Eliminar"
                                    cancelText="Cancelar"
                                    placement="rightTop"
                                    okButtonProps={{ danger: true }}
                                >
                                    <Button danger size="small" type="link" icon={<DeleteOutlined />} title="Eliminar departamento" />
                                </Popconfirm>
                            </>
                        }
                    </div>}
                    style={{ marginTop: "2rem", paddingLeft: "1rem"}}
                    itemLayout="horizontal"
                    dataSource={usuarios.filter(u => u.departamento && u.departamento.id === d.id)}
                    renderItem={ user => (<FilaUsuario key={user.id} userApp={userApp} user={user} />) }
                    locale={{ emptyText: "Departamento sin usuarios" }}
                />
            ))
        }

        {
            !loadingDepartamentos && !errorDepartamentos
            && !loadingUsuarios && !errorUsuarios
            && usuarios.filter(u => !u.departamento).length > 0
            && <List
                header={<h2>Sin departamento</h2>}
                style={{ marginTop: "2rem", paddingLeft: "1rem"}}
                itemLayout="horizontal"
                dataSource={usuarios.filter(u => !u.departamento)}
                renderItem={ user => <FilaUsuario key={user.id} userApp={userApp} user={user} /> }
            />
        }


    </div>;
}

export default Users;