import actionTypes from '../actions/taskOnPlay'

const initialState = {
    taskOnPlay: null
}

const taskOnPlay = (state = initialState, { type, payload }) => {
    switch(type) {
        case actionTypes.SET_TASK_ON_PLAY:
            return {
                taskOnPlay: payload
            };
        default:
            return {
                ...state
            }
    }
}

export default taskOnPlay
