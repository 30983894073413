import React, { useCallback, useEffect, useState} from 'react'
import {Avatar, Layout, Menu, PageHeader} from 'antd';
import GoogleLogoutButton from "./components/googleButtons/GoogleLogoutButton";
import GoogleLoginButton from "./components/googleButtons/GoogleLoginButton";
import { useParams, useRouteMatch } from "react-router-dom";
import { Alert } from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
// import { putIAmOnline } from "./helpers/api/putIAmOnline";
import TimeTaskLine from "./components/utils/TimeTaskLine";
import moment from "moment";
import socketIOClient from "socket.io-client";
import { useFetchTaskOnPlay } from "./hooks/useFetchTaskOnPlay";
import { /* getMomentDatetime, */ isOldOpenTask } from "./helpers/utils";

import { useSelector, useDispatch } from "react-redux";
import { actualizarUltimaActualizacion } from './redux/actions/ultimaActualizacion';
import { setTaskOnPlay } from "./redux/actions/taskOnPlay";
import {baseUrl} from "./helpers/fetchHelper";

const { Content } = Layout;


export const PrincipalLayout = ({ getSection }) => {

    useEffect(() => {
        if(window.location.protocol !== "https:"){
            window.open("https://" + window.location.hostname, "_self");
        }
    }, []);

    const userApp = useSelector(state => state.userApp.userApp);
    const dispatch = useDispatch();

    const [ errorCookies, setErrorCookies ] = useState(false);
    const [ userGoogle, setUserGoogle ] = useState(null);

    // const [ iAmOnline, setIAmOnline ] = useState(false);

    const { path } = useRouteMatch();
    const urlParams = useParams() || {};

    const { data: taskOnPlay } = useFetchTaskOnPlay();
    dispatch(setTaskOnPlay(taskOnPlay));
    const oldTaskOpen = taskOnPlay? isOldOpenTask(taskOnPlay) : false;


    // const avisarSigoConectado = useCallback(() => putIAmOnline({
    // }), [userApp]);


    const activarSocket = useCallback(() => {
        if(!userApp) { return; }
        const newSocket = socketIOClient(baseUrl, {
            query: {
                userId: userApp.id
            }
        });

        const onUpdate = data => {
            dispatch(actualizarUltimaActualizacion(moment().format('YYYYMMDDHHmmssss')));
        };

        // const onUser = data => {
        //     const { id, online } = data;
        //     if(id === userApp.id){
        //         if(online){
        //             setIAmOnline(true);
        //         } else {
        //             avisarSigoConectado();
        //         }
        //     }
        // };

        newSocket.on('connect', () => { console.log('Conectado') });
        newSocket.on('disconnect', () => { console.log('Desonectado') });

        newSocket.on('all', onUpdate);
        newSocket.on('sync', onUpdate );
        // newSocket.on('user', onUser );

        return () => {
            if(newSocket){
                newSocket.off('all', onUpdate);
                newSocket.off('sync', onUpdate);
                // newSocket.off('user', onUser);
            }
        }
    }, [ /* avisarSigoConectado, */ userApp, dispatch]);
    useEffect( () => activarSocket(), [activarSocket]);

    const sectionParams = {
        ...urlParams,
        userGoogle
    };

    return (
        <Layout className="layout layout-principal">
            <PageHeader
                className="site-page-header cabeceraPrincipal"
                title={<a href="/" style={{ color: "#333"}}><span style={{ color: "#1890ff" }}>WORK</span>ECOMMIUM</a>}
                subTitle={taskOnPlay && !oldTaskOpen &&
                    <TimeTaskLine task={taskOnPlay} />
                }
                extra={[
                    !userGoogle && <GoogleLoginButton key='GoogleLoginButton' setUserGoogle={setUserGoogle} setErrorCookies={setErrorCookies} />,
                    userGoogle && <Avatar key='Avatar' src={userGoogle.imageUrl} />,
                    userGoogle && userGoogle.email,
                    userGoogle && <GoogleLogoutButton key='GoogleLogoutButton' />,
                ]}
            >
                <Menu className="menuPrincipal" mode="horizontal" defaultSelectedKeys={[ path ]}>
                    { userGoogle && userApp &&
                    <>
                        <Menu.Item key="/">
                            <a href="/" target="_self">Dashboard</a>
                        </Menu.Item>
                        <Menu.Item key="/tasks/" disabled={oldTaskOpen} >
                            <a href="/tasks/" target="_self" >Mis Tareas</a>
                        </Menu.Item>
                        <Menu.Item key="/users/" disabled={oldTaskOpen} >
                            <a href="/users/" target="_self">Usuarios</a>
                        </Menu.Item>
                        { userApp.administrador &&
                        <Menu.Item key="/projects/" disabled={oldTaskOpen} >
                            <a href="/projects/" target="_self">Proyectos</a>
                        </Menu.Item>
                        }
                        <Menu.Item key="/configuration/" disabled={oldTaskOpen} >
                            <a href="/configuration/" target="_self">Configuración</a>
                        </Menu.Item>
                    </>
                    }
                </Menu>
            </PageHeader>

            {errorCookies && <Alert type="error" message="Para poder acceder debe activar las cookies en su navegador" icon={<WarningTwoTone twoToneColor="#f81d22" />} banner />}
            <Content className="layout-content-principal" style={{ padding: '2rem' }}>
                <div className="site-layout-content">
                    { userApp
                        && getSection({ ...sectionParams })
                    }
                </div>
            </Content>
        </Layout>

    )
}
