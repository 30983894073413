import React, {useState} from 'react'
import { Form, Input, Button, Alert } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import {putRedboothConfig} from "../../helpers/api/putRedboothConfig";


const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 20 },
};

const tailLayout = {
    wrapperCol: { offset: 6 },
};

export const ConfigRedboothApi = ({ userGoogle }) => {
    const [form] = Form.useForm();
    const [ error, setError ] = useState(null);

    const onFinish = (values) => {
        putRedboothConfig({
            ...values,
            callback: ({ error, result }) => {
                if(!error){
                    window.open('/configuration/', "_self");
                } else {
                    setError(error);
                }
            }
        });
    };

    const redirect_uri = window.location.origin + '/configuration/auth-redbooth/';

    return (
        <div>
            <Form {...layout} form={form} className="config-redbooth-form" name="control-hooks" initialValues={{ client_id: "35d2db082a2c822f64c3c7823db55bdbb0847e21b9ee0a8dd494f530e1de7c64", client_secret: "152fe24ab479997130af6b8b173290b24c398a2c9c4ffb6c9d13537d6b33d536", redirect_uri }} onFinish={onFinish}>
                { error && <Alert message="Error guardando configuración" type="error" style={{ marginBottom: "1rem" }} showIcon />}
                <Alert
                    message="Añadir Redirect Url"
                    description={(
                        <div>
                            <b>{redirect_uri}</b><br/>
                            No olvides añadir esta url como Redirect Url a tu aplicación de Redbooth,
                            <br />
                            accediendo a <a href="https://redbooth.com/oauth2/applications" target="_blank" rel="noopener noreferrer">https://redbooth.com/oauth2/applications</a>
                        </div>
                    )}
                    type="info"
                    icon={<PlusCircleOutlined />}
                    showIcon
                    style={{ marginBottom: "1rem" }}
                />
                <Form.Item name="client_id" label="Client ID" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>
                <Form.Item name="client_secret" label="Client SECRET" rules={[{ required: true }]}>
                    <Input />
                </Form.Item>


                <Form.Item {...tailLayout} >
                    <Button type="primary" htmlType="submit">
                        Guardar configuración
                    </Button>
                </Form.Item>

                <Form.Item name="redirect_uri" rules={[{ required: true }]}>
                    <Input type="hidden" />
                </Form.Item>

            </Form>
        </div>
    )
}
