import React, {useState} from "react";
import { Avatar, Button, List, Steps, Popconfirm, TimePicker } from "antd";
import {
    QuestionCircleOutlined,
    CloseOutlined,
    PauseOutlined,
    PlayCircleFilled,
    WarningFilled,
} from '@ant-design/icons';
import {putStopTasks} from "../../helpers/api/putStopTasks";
import {deleteTime} from "../../helpers/api/deleteTime";
import {putFinishTimeTask} from "../../helpers/api/putFinishTimeTask";
import {putStartTimeTask} from "../../helpers/api/putStartTimeTask";
import {getMomentDatetime} from "../../helpers/utils";
import {useSelector} from "react-redux";

const { Step } = Steps;

const FilaTiempoTarea = ({ time, task }) => {

    const userApp = useSelector(state => state.userApp.userApp);
    const { token } = userApp;

    const [ error, setError ] = useState(null);
    const [ editandoStart, setEditandoStart ] = useState(false);
    const [ editandoFinish, setEditandoFinish ] = useState(false);

    const putOnPause = task => {
        putStopTasks({
            taskId: task.id,
            token,
            callback: ({ error, result }) => {
                if(error){
                    setError(error);
                }
            }
        });
    }

    const onDeleteTime = () => {
        deleteTime({
            timeId: time.id,
            token,
            callback: ({ error, result }) => {
                if(error){
                    setError(error);
                }
            }
        });
    }

    const guardarHoraInicio = ( momentFechaSeleccionada ) => {
        setEditandoStart(false);

        if(momentFechaSeleccionada.isAfter(getMomentDatetime(time.fin))){
            setError("La hora selecciona es posterior a la hora de finalizacion")
        } else {
            putStartTimeTask({
                taskId: task.id,
                timeId: time.id,
                datetime: momentFechaSeleccionada.utc().toDate(),
                callback: ({ error, result }) => {
                    if(error){
                        setError("Error guardando la hora seleccionada")
                    }
                },
                token
            });
        }

    }

    const guardarHoraFinalizacion = ( momentFechaSeleccionada ) => {
        setEditandoFinish(false);

        if(momentFechaSeleccionada.isBefore(getMomentDatetime(time.inicio))){
            setError("La hora selecciona es anterior a la hora de inicio");
        } else {
            putFinishTimeTask({
                timeId: time.id,
                date: momentFechaSeleccionada.utc().toDate(),
                callback: ({ error, result }) => {
                    if(error){
                        setError("Error guardando la hora seleccionada");
                    }
                },
                token
            });
        }
    }

    const puedeEditar = time.user_id === userApp.id;

    return <List.Item >
        <List.Item.Meta
            avatar={error && <span style={{ color: "#ff4d4f", marginRight: "0.5rem" }}><WarningFilled /> Error </span>}

            description={<>
                <Steps>
                    <Step
                        icon={<Avatar src={ time.profile_avatar_url } title={ `${time.first_name} ${time.last_name}` } />}
                        status={ (!time.fin) ? "finish" : "wait" }
                        title={
                            <>
                                { editandoStart && <TimePicker open autoFocus defaultValue={getMomentDatetime(time.inicio)} format="DD/MM/YYYY HH:mm" showNow={false} onOk={guardarHoraInicio} />}
                                { !editandoStart && <span style={{ width: "8rem", display: "inline-block" }} onDoubleClick={() => { if(time.fin){ setEditandoStart(true)} }} >{getMomentDatetime(time.inicio).format("DD/MM/YYYY kk:mm")}</span> }
                            </>
                        }
                    />

                    { time.fin &&
                        <Step
                            status="wait"
                            icon={<></>}
                            title={
                                <span style={{ width: "12rem", display: "inline-block" }}>
                                    {!editandoFinish && <span onDoubleClick={() => puedeEditar && setEditandoFinish(true)} >{getMomentDatetime(time.fin).format("DD/MM/YYYY kk:mm")}</span>}
                                    { editandoFinish && <TimePicker open autoFocus defaultValue={getMomentDatetime(time.fin)} format="DD/MM/YYYY HH:mm"  showNow={false} onOk={guardarHoraFinalizacion} /> }
                                    {
                                        !editandoStart && !editandoFinish && puedeEditar && <>
                                            <Popconfirm
                                                title="¿Eliminar este registro?"
                                                onConfirm={onDeleteTime}
                                                okText="Eliminar"
                                                okButtonProps={{ danger: true }}
                                                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}

                                                cancelText="Cancelar"
                                            >
                                                <Button type="link" icon={<CloseOutlined />} danger title="Eliminar" />
                                            </Popconfirm>
                                        </>
                                    }

                                </span>
                            }
                        />
                    }
                    { !time.fin &&
                        <Step
                            status="process"
                            icon={<PlayCircleFilled className="animacionParpadeoOnPlay" />}
                            title={
                                <>
                                    <span style={{ display: "inline-block", paddingRight: "1rem" }}>
                                        {
                                            time.usuario === userApp.id && <>
                                                <Button type="default" size="small" icon={<PauseOutlined />} danger={error} title="Detener" style={{ marginRight: "0.5rem" }} onClick={ () => !error && putOnPause(task) } />
                                            </>
                                        }
                                        En curso
                                    </span>
                                </>
                            }
                        />
                    }
                </Steps>

            </>}
        />
    </List.Item>;
};

export default FilaTiempoTarea;