import React from "react";
import {CrownTwoTone} from "@ant-design/icons";
import {Alert, Typography} from "antd";

const { Text } = Typography;

const EstupendoUsuarioSinTareas = () => {

    return <>
        <Alert
            message={<><Text strong>¡Estupendo!</Text> No tiene tareas pendientes</>}
            description={<>
                Ahora está disponible para ocuparse de nuevas tareas.
                <br />
                Ponte en contacto con el usuario o con tu administrador para poder asignarle nuevas tareas.
                <br />
                Puedes encontrar a tu administrador en la sección de <span style={{ fontWeight: "bold"}} >Usuarios</span>.
            </>}
            type="success"
            showIcon
            icon={<CrownTwoTone twoToneColor="#52c41a" />}
            style={{ marginBottom: "1rem" }}
        />
    </>;
};

export default EstupendoUsuarioSinTareas;