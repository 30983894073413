import actionTypes from '../actions/userApp'

const initialState = {
    userApp: null
}

const userApp = (state = initialState, { type, payload }) => {
    switch(type) {
        case actionTypes.SET_USERAPP:
            return {
                userApp: payload
            };
        default:
            return {
                ...state
            }
    }
}

export default userApp
