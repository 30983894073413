import {useFetch} from "./useFetch";
import {baseUrl} from "../helpers/fetchHelper";
import {useSelector} from "react-redux";

export const useFetchTask = ({ taskId }) => {
    const query = {}, data = {};

    const userApp = useSelector(state => state.userApp.userApp);
    const ultimaActualizacion = useSelector(state => state.ultimaActualizacion.ultimaActualizacion);

    let token = '';
    let url = '';
    if(userApp){
        token = userApp.token;
        url = `${baseUrl}/api/tareas/${taskId}`;
    }



    return useFetch(url, { query, data }, ultimaActualizacion, token );
}
