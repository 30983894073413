
import moment from "moment";


export const getColorFromString = (value) => {
    const hashCode = (str) => { // java String#hashCode
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
        }
        return hash;
    }

    const intToRGB = (i) => {
        const c = (i & 0x00FFFFFF)
            .toString(16)
            .toUpperCase();
        const code = "00000".substring(0, 6 - c.length) + c;
        return `#${code}`;
    }

    return intToRGB(hashCode(value))
}

export const getMomentDatetime = (datetime) => {
    if(!datetime){
        console.error("Fecha invalida");
        return null;
    }
    return moment.utc(datetime).local();
}

export const isOldOpenTask = (taskOnPlay) => {
    const timeOnPlay = taskOnPlay.tiempos.find(tm => !tm.fin);
    return timeOnPlay && moment().format('YYYYMMDD') !== getMomentDatetime(timeOnPlay.inicio).format('YYYYMMDD');
}

export const isTaskOnPlay = task => {
    return (task.tiempos.length > 0 && task.tiempos.some( tm => !tm.fin ));
}