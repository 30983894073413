import React, {useCallback} from "react";
import {Form, Button, Select, PageHeader} from 'antd';
import { putUsuarioDepartamento } from "../helpers/api/putUsuarioDepartamento";

const { Option } = Select;

const layout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
};
const tailLayout = {
    wrapperCol: { offset: 6, span: 14 },
};

const FormularioCambiarDepartamento = ({ userApp, departamentos, user, cerrarModal }) => {

    const { token } = userApp;

    const onFinish = useCallback(values => {
        const { departamento } = values;
        putUsuarioDepartamento({
            departamentoId: departamento,
            usuarioId: user.id,
            callback: ({ error, result }) => {
                if(!error){
                    cerrarModal();
                }
            },
            token
        });

    }, [ cerrarModal, user.id, token ]);

    return (
        <>
            <PageHeader
                title={`${ user.first_name } ${ user.last_name }`}
                className="site-page-header"
                avatar={{
                    src: user.profile_avatar_url
                }}
            />
            <Form
                {...layout}
                name="basic"
                initialValues={{ departamento: user.departamento_id }}
                onFinish={onFinish}
            >
                <Form.Item name="departamento" label="Departamento" rules={[{ required: true }]}>
                    <Select placeholder="Selecciona un departamento" >
                        <Option value={0}>Sin departamento</Option>
                        { departamentos
                            .sort((a,b) => a.nombre.localeCompare(b.nombre))
                            .map( d => (
                                <Option value={d.id}>{d.nombre}</Option>
                            ))
                        }
                    </Select>
                </Form.Item>

                <Form.Item {...tailLayout}>
                    <Button type="primary" htmlType="submit">
                        Asignar departamento
                    </Button>
                </Form.Item>

            </Form>
        </>

    );
}

export default FormularioCambiarDepartamento;